<template>
  <div class="app-container">
    <el-card class="box-card">
      <h3>编辑商品</h3>
      <el-form ref="dataForm" :rules="rules" :model="product" label-width="150px">
        <el-form-item prop="goodTypeId" label="商品分类">
          <el-select v-model="product.goodTypeId" placeholder="请选择商品类型">
            <template v-for="(item, index) in goodsType">
              <el-option
                  :label="item.name"
                  :value="item.goodstypeId"
                  :key="index"
              />
            </template>
          </el-select>
          <el-button type="primary" class="ma ml10" @click="open"
          >添加分类
          </el-button
          >
        </el-form-item>
        <el-form-item prop="videUrl" label="主图视频">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传视频的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式MP4。大小不超过{{singleVideoSize}}M。
          </p>
          <!---->
          <div class="videoWrap">
            <el-upload
              class="avatar-uploader"
              :action="uploadPath"
              :headers="uploadHeader"
              :show-file-list="false"
              :before-upload="beforeVideoUpload"
              :on-success="handleSuccessVideo"
              :on-remove="handleRemoveVideo"
              :file-list="videoFileList"
              accept=".mp4"
              >
              <video v-if="videoFileList && videoFileList.length" :src="`https://mzmpic.oss-cn-beijing.aliyuncs.com/${videoFileList[0]}`" width="100%" height="100%" controls>
              </video>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-button @click="handleRemoveVideo">重置</el-button>
          </div>

        </el-form-item>
        <el-form-item prop="imgList" label="商品图片">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过{{singleImgSize}}KB，商品图片一共可以上传5张，默认第一张为主图封面
          </p>
          <div class="pictorWrap" style="display: flex">
            <el-upload
                :action="uploadPath"
                :headers="uploadHeader"
                :class="{
                  disabled: uploadDisabled,
                }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :before-upload="beforePictureCardUpload"
                :on-remove="handleRemove"
                :on-success="handleimgsUrl"
                :file-list.sync="imgsFileList"
								accept="image/jpeg,image/png"
                :limit="5"
                ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
                :close-on-click-modal="false"
            >
              <img width="100%" :src="dialogImageUrl" alt=""/>
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="商品名称" prop="title">
          <el-input v-model="product.goodsName" />
        </el-form-item>
         <el-form-item label="排序" prop="sort">
          <el-input v-model="product.sort" placeholder="排序" type="number" />
       </el-form-item>

       <el-form-item label="是否定时发布" prop="isTiming">
          <el-radio-group v-model="product.isTiming">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

       <!-- <el-form-item label="运费模板" prop="freightTemplate">
         <el-select v-model="product.freightId" placeholder="选择商品运费模板">
           <el-option v-for="(item, index) in freightList" :key="index" :label="item.name" :value="item.id"/>
         </el-select>
       </el-form-item> -->

        <el-form-item label="配送方式" prop="">
          <el-select v-model="product.psType" placeholder="请选择">
            <el-option :key="1" label="快递邮寄" :value="1"></el-option>
            <el-option :key="2" label="到店自提" :value="2"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="定时发布时间" prop="releaseTime" v-if="product.isTiming">
          <el-date-picker
            v-model="product.releaseTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="是否是经销商页面" prop="isDealerType">
          <el-radio-group v-model="product.isDealerType">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="是否在售" prop="isPutOn">
          <el-radio-group v-model="product.isPutOn">
            <el-radio :label="true">在售</el-radio>
            <el-radio :label="false">下架</el-radio>
          </el-radio-group>
        </el-form-item>
<!-- 
        <el-form-item label="是否参加分销" label-width="130px">
          <el-radio-group v-model="product.isDistribution">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <!-- <el-form-item label="是否发布到产品中心" label-width="150px">
          <el-radio-group v-model="product.isPublic">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item> -->





<!--        <el-form-item label="所属类目" prop="category">-->
<!--          <el-cascader-->
<!--            :options="categoryList"-->
<!--            :props="{label:'title', value:'id', children:'childrenList'}"-->
<!--            v-model="categoryIds"-->
<!--            expand-trigger="hover"-->
<!--            @change="handleCategoryChange"-->
<!--          />-->
<!--        </el-form-item>-->

        <el-form-item label="是否多规格" prop="isMutil">
          <el-radio-group v-model="product.isMutil">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <div v-if="!product.isMutil">
        <el-form-item label="原价" prop="originalPrice">
          <el-input   v-model="product.skuList[0].originalPrice" placeholder="0.00"  oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="现价" prop="price">
          <el-input v-model="product.skuList[0].price" placeholder="0.00" oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
<!--        <el-form-item label="VIP价格" prop="vipPrice">-->
<!--          <el-input v-model="product.skuList[0].vipPrice" placeholder="0.00">-->
<!--            <template slot="append">元</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="剩余库存" prop="stock">
          <el-input v-model="product.skuList[0].stock"  min="0" placeholder="0" oninput="value=value.replace(/[^\d]/g,'')"/>
        </el-form-item>
        </div>
<!--        <el-form-item label="商品简介" prop="description">-->
<!--          <el-input v-model="product.description" />-->
<!--        </el-form-item>-->

        <el-form-item label="商品详情" prop="goodsDetails">
          <quill-editor
              style="height: 100%"
              v-model="product.goodsDetails"
              ref="myQuillEditor"
              :options="editorOption"
          />
        </el-form-item>

      </el-form>
    </el-card>

    <div v-if="product.isMutil">
    <el-card class="box-card">
      <h3>规格维度</h3>
      <el-button :plain="true" type="primary" @click="createSpecDialogShow">添加</el-button>
      <el-table :data="specList">
        <el-table-column property="title" label="维度名称" />
        <el-table-column
          align="center"
          label="操作"
          width="180"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="updateSpecDialogShow(scope.row)">修改</el-button>
            <el-button type="danger" size="mini" @click="handleSpecDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加规格的Dialog -->
      <el-dialog :visible.sync="specVisiable" :title="specStatusDialogMap[specStatus]" :modal-append-to-body="false">
        <el-form
          ref="specForm"
          :model="specForm"
          :rules="specRules"
          :close-on-click-modal="false"
          status-icon
          label-position="left"
          label-width="100px"
          style="width: 400px; margin-left:50px;"
          @submit.native.prevent
        >
          <el-input v-model="specForm.id" type="hidden" />
          <el-form-item label="类型名称" prop="title">
            <el-input v-model="specForm.title" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="specVisiable = false">取消</el-button>
          <el-button type="primary" @click="handleSpecAdd">确定</el-button>
        </div>
      </el-dialog>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h4>商品(Sku)详情</h4>
        <el-button :plain="true" type="primary" @click="skuBatchPriceVisiable = true">批量定价</el-button>
      </div>
      <div v-for="(item, index) in specList" :key="index" class="tag-group">
        <el-tag
          size="medium"
          type="warning"
        >
          {{ item.title }}
        </el-tag>
        <el-tag
          v-for="tag in item.values"
          :key="tag"
          :disable-transitions="false"
          size="medium"
          closable
          @close="handleSkuTagClose(index, tag, item)">
          {{ tag }}
        </el-tag>
        <el-input
          v-if="item.inputSkuTagVisible"
          v-model="item.inputSkuTagValue"
          class="input-new-tag"
          size="small"
          maxlength="30"
          @keyup.enter.native="handleSkuTagInputConfirm(index, item)"
          @blur="handleSkuTagInputConfirm(index, item)"
        />
        <el-button v-else class="button-new-tag" size="small" @click="handleSkuTagCreate(item)">+ 添加</el-button>

      </div>
      <el-table
        :data="skuTableData"
        :span-method="skuTableSpanMethod"
        border
        style="width: 100%; margin-top: 20px">
        <el-table-column
          v-for="(item, index) in specList"
          :key="index"
          :prop="'id_' + item.title"
          :label="item.title"
          />
        <el-table-column
          prop="price"
          label="￥价格(元)">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.price" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </template>
        </el-table-column>
        <el-table-column
          prop="vipPrice"
          label="￥VIP价(元)">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.vipPrice" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </template>
        </el-table-column>
        <el-table-column
          prop="originalPrice"
          label="￥原价(元)">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.originalPrice" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </template>
        </el-table-column>
        <el-table-column
          :label="'库存(' + (product.unit ? product.unit : '件') + ')'"
          prop="stock">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.stock" :precision="0" :max="2147483647" :min="0" size="small" />
          </template>
        </el-table-column>
        <el-table-column
          prop="img"
          label="SKU图片"
          width="80"
        >
          <template slot-scope="scope">
            <!-- on-success 接收的是一个变量，而非事件，也就是说是一个函数指针，所以无法将row给传过去 -->
            <!--               :before-upload="beforSkuImgUpload" -->
            <div class="skuImgBox">
            <el-upload
              :show-file-list="false"
              :on-success="(e, file) => {
                handleSkuImgSuccess(e, file, scope)
              }"
              :before-upload="beforePictureCardUpload"
              :action="uploadPath"
              :headers="uploadHeader"
              accept=".jpg, .jpeg, .png, .gif"
              class="sku-uploader">
              <img v-if="scope.row.img" :src="baseImgUrl+scope.row.img" class="sku">
              <i v-else class="el-icon-plus sku-uploader-icon"/>
            </el-upload>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 批量定价Dialog -->
      <el-dialog :visible.sync="skuBatchPriceVisiable" title="批量定价" :modal-append-to-body="false">
        <el-form
          ref="skuBatchPriceForm"
          :model="skuBatchPriceForm"
          :close-on-click-modal="false"
          status-icon
          label-position="left"
          label-width="100px"
          style="width: 400px; margin-left:50px;"
        >
          <el-form-item label="现价(￥)" prop="price">
            <el-input-number v-model="skuBatchPriceForm.price" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </el-form-item>
          <el-form-item label="VIP价格(￥)" prop="vipPrice">
            <el-input-number v-model="skuBatchPriceForm.vipPrice" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </el-form-item>
          <el-form-item label="吊牌价(￥)" prop="originalPrice">
            <el-input-number v-model="skuBatchPriceForm.originalPrice" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </el-form-item>
          <el-form-item label="库存" prop="stock">
            <el-input-number v-model="skuBatchPriceForm.stock" :precision="2" :min="0.01" :max="2147483647" size="small" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="skuBatchPriceVisiable = false">取消</el-button>
          <el-button type="primary" @click="handleBatchPrice">确定</el-button>
        </div>
      </el-dialog>
    </el-card>
    </div>
    <div class="op-container">
      <el-button @click="handleCancel">取消</el-button>
      <el-button v-if="!product.goodsId" type="primary" @click="handleCreate">保存商品</el-button>
      <el-button v-if="product.goodsId" type="primary" @click="handleEdit">更新商品</el-button>
    </div>
  </div>
</template>

<script>
import { detailProduct, editProduct, createProduct } from '@/api/product'
import { categoryTree } from '@/api/category'
import { listFreight } from '@/api/freight'
import { uploadPath, createStorage } from '@/api/storage'
// import Editor from '@tinymce/tinymce-vue'
import { MessageBox } from 'element-ui'
import product from "../marketingActivities/spike/components/product";
import {post,get,put} from "../../utils/request";

import {baseImgUrl} from "../../utils/constant";
import httpUtil from "../../utils/httpUtil";
import quillConfig from "@/utils/quill-config.js";
import {quillEditor, Quill} from "vue-quill-editor";
import {ImageDrop} from "quill-image-drop-module";
import resizeImage from "quill-image-resize-module";
import  {getGoodsTypeInfo,addGoodsType} from "../../api/coupon";
import {noSpecialCode} from '@/utils/index'
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import Sortable from 'sortablejs';
export default {
  name: 'ProductUpsert',
  components: { quillEditor },
  data() {
    let token = localStorage.getItem("token")
    return {
      editorOption: quillConfig,
      goodsType: [],
      baseImgUrl:baseImgUrl,
      dialogVisible: false,
      imgList: [],
      dialogImageUrl: "",
      uploadPath: this.$store.state.uploadingUrl,
      uploadHeader: {
        token: token,
      },
      psValue: '1', 
      yfValue: '',
      videoFileList: [],
      isTiming: false,
      releaseTime: '',
      isDealerType: false,
      freightList: [],
      imgsFileList: [],
      categoryList: [],
      categoryIds: [],
      skuTableData: [],
      product: {
        imgList: [],
        type: 0,
        isMutil: false,
        isPutOn: true,
        isDistribution: false, //分销售
        isPublic: false, //上架
        goodsName: '',
        skuList: [{}],
        psType:1,
        freightId:''
      },
      attributeVisiable: false,
      attributeForm: { attribute: '', value: '' },
      attributes: [],
      skuBatchPriceVisiable: false,
      skuBatchPriceForm: {},
      specVisiable: false,
      specForm: { title: undefined },
      skuList: [],
      specList: [],
      skuStatusDialogMap: { 'create': '创建sku', 'update': '更新sku' },
      specStatusDialogMap: { 'create': '创建规格', 'update': '更新规格' },
      skuStatus: '',
      specStatus: '',
      rules: {
        status: [
          { required: true, message: '请选择商品状态', trigger: 'blur' }
        ],
        goodsName: [
          { required: true, message: '商品名称不能为空', trigger: 'blur' }
        ],
        priceRaw: [
          { required: true, message: '商品现价不能为空', trigger: 'blur' }
        ],
        originalPriceRaw: [
          { required: true, message: '商品原价不能为空', trigger: 'blur' }
        ],
        vipPriceRaw: [
          { required: true, message: '商品ViP价格不能为空', trigger: 'blur' }
        ],
        goodsDetails: [{ required: true, message: '请填写商品详情', trigger: 'blur' }]
      },
      specRules: {
        title: [
          { required: true, message: '规格维度标题不能为空', trigger: 'blur' }
        ]
      },
      attrRules: {
        attribute: [
          { required: true, message: '属性名称不能为空', trigger: 'blur' }
        ],
        value: [
          { required: true, message: '属性值不能为空', trigger: 'blur' }
        ]
      },
      editorInit: {
        language: 'zh_CN',
        convert_urls: false,
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function(blobInfo, success, failure) {
          const formData = new FormData()
          formData.append('file', blobInfo.blob())
          createStorage(formData).then(res => {
            success(res.data.url)
          }).catch(() => {
            failure('上传失败，请重新上传')
          })
        }
      },
      singleImgSize: 500,
      singleVideoSize: 20
    }
  },
  computed: {
    uploadDisabled() {
      return this.imgsFileList.length >= 5;
    },
  },
  created() {
    quillConfig.placeholder = '请填写商品详情'
    this.init()
    this.initGoodType()
    this.$nextTick(() => {
      this.initDragSort();
    })
    this.singleImgSize = JSON.parse(localStorage.getItem('info')).companyList[0].imageSize
    this.singleVideoSize = JSON.parse(localStorage.getItem('info')).singleVideoSize
  },
  methods: {
    initDragSort() {
       // 支持拖拽排序
       let that = this
       const el = this.$refs.upload.$el.querySelectorAll('.pictorWrap .el-upload-list')[0];
       Sortable.create(el, {
         onEnd: ({ oldIndex, newIndex }) => {
           // 交换位置
           const arr = that.imgsFileList;
           const page = arr[oldIndex];
           arr.splice(oldIndex, 1);
           arr.splice(newIndex, 0, page);
           that.imgsFileList = arr
           that.imgList = that.imgsFileList
          //  that.imgList = that.imgsFileList.map(item => item.url.replace(/http:\/\/mzmpic.oss-cn-beijing.aliyuncs.com\//, ''))
         }
       });
    },
    handleRemoveVideo(file, fileList) {
      this.videoFileList = []
    },
    handleSuccessVideo(response, file, fileList) {
      if(response.code!== 200) {
        this.$message({
          type: 'error',
          message: response.message
        })
        this.videoFileList = []
      }else  {
        this.videoFileList = [response.data]
      }
    },
    open(row) {
      this.$prompt("请输入商品类型名称", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async ({value}) => {
            await addGoodsType({
              name: value,
              sort: 0,
              isDel: 0,
            });
            const datas = await getGoodsTypeInfo({}); //获取分类数据
            this.goodsType = datas.data;
            this.$message({
              type: "success",
              message: "添加成功",
              center: true,
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "操作取消",
              center: true,
            });
          });
    },
    initGoodType(){
      getGoodsTypeInfo({}).then(res=>{
        console.log('分类',res)
        this.goodsType=res.data
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforePictureCardUpload(file) {
      let _this = this
      const is500k = file.size / 1024 < this.singleImgSize; // 限制小于500kb
      if (!is500k) {
        this.$message.error({
          title: '图片上传失败',
          message: `图片大小不可超过${this.singleImgSize}kB`
        })
      }
      return is500k
    },
    beforeVideoUpload(file) {
      let is20M = file.size / 1024 / 1024 < this.singleVideoSize
      if (!is20M) {
        this.$message.error({
          title: '视频上传失败',
          message: `视频大小不可超过${this.singleVideoSize}M`
        })
      }
      return is20M
    },
    init: function() {
      const productId = this.$route.query.id
      if (productId) {
        get('/goods/'+productId).then(response => {
          // 深拷贝
          this.product = JSON.parse(JSON.stringify(response))
          console.log('数据',this.product)
          this.imgList=this.product.imgList.split(",")
          this.attributes = response.attributeList ? response.attributeList : []
          this.categoryIds = response.categoryIds
          this.skuList = response.skuList
          this.videoFileList = response.videoUrl ? [response.videoUrl] : []
          for (let i = 0; i < response.spuList.length; i++) {
            response.spuList[i].values = []
            response.spuList[i].inputSkuTagValue = false
            response.spuList[i].inputSkuTagValue = ''
          }
          // spu规格列表
          this.specList = response.spuList
          this.imgsFileList = []
          for (var i = 0; i < this.imgList.length; i++) {
            this.imgsFileList.push({
              url: this.baseImgUrl+this.imgList[i]
            })
          }
          let stock = 0
          if (this.product.skuList) {
            for (var j = 0; j < this.product.skuList.length; j++) {
              this.skuList[j].price = this.skuList[j].price / 100
              this.skuList[j].originalPrice = this.skuList[j].originalPrice / 100
              this.skuList[j].vipPrice = this.skuList[j].vipPrice / 100
              stock += this.skuList[j].stock
            }
          }
          this.product.stock = stock
          if (this.product.skuList) {
            this.product.skuList.forEach(item => {
              this.product.stock += item.stock
              item.price = item.price / 100
              item.originalPrice = item.originalPrice / 100
              item.vipPrice = item.vipPrice / 100
              const tempArray = item.specification ? item.specification.split(',') : []
              for (let i = 0; i < tempArray.length; i++) {
                const skuSpecTemp = tempArray[i]
                const singleArray = skuSpecTemp.split('_')
                item['id_' + singleArray[0]] = singleArray[1]
                for (let j = 0; j < this.specList.length; j++) {
                  if (this.specList[j].title === singleArray[0]) {
                    // 若是同一个Key。则将value追加到其values里面
                    if (this.specList[j].values) {
                      if (this.specList[j].values.indexOf(singleArray[1]) < 0) {
                        this.specList[j].values.push(singleArray[1])
                      }
                    } else {
                      this.specList[j].inputSkuTagVisible = false
                      this.specList[j].inputSkuTagValue = ''
                      this.specList[j].values = [singleArray[1]]
                    }
                  }
                }
              }
            })
          }
          this.skuTableData = JSON.parse(JSON.stringify(this.product.skuList))
        })
      }

      // categoryTree().then(response => {
      //   this.categoryList = response.data.data
      // })
      
      get('/freight?pageNum=1&pageSize=999').then(response => {
        this.freightList = response.list
      })
      // listFreight().then(response => {
      //   debugger
      //   
      // })
    },
    handleCategoryChange(value) {
      this.product.categoryId = value[value.length - 1]
    },
    handleCancel: function() {
      this.$router.push({ path: '/product/list' })
    },
    handleCreate: function() {
      this.handleUpsert('create')
    },
    handleEdit: function() {
      this.handleUpsert('edit')
    },
    /**
     * 执行更新添加操作，传入操作action字符串: 'create', 'edit'
     */
    handleUpsert(action) {
      var that = this
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
         if (this.imgList.length === 0) {
           this.$notify.error({
             title: '失败',
             message: '请上传至少一张图片'
           })
         } else {
             let goodsParams={};
             Object.assign(goodsParams,this.product)
             goodsParams.imgList=this.imgList.join(",")
             if(this.product.isMutil){
               const skuList = []
               for (let i = 0; i < this.skuTableData.length; i++) {
                 let title = ''
                 let specification = ''
                 for (const field in this.skuTableData[i]) {
                   if (field.startsWith('id_')) {
                     title += (this.skuTableData[i][field] + ',')
                     specification += (field.substring(3) + '_' + this.skuTableData[i][field] + ',')
                   }
                 }
                 title = title.substring(0, title.length - 1)
                 specification = specification.substring(0, specification.length - 1)
                 if(!this.skuTableData[i].originalPrice){
                   this.$notify.error({
                     title: '失败',
                     message: '请输入原价'
                   })
                   return;
                 }
                 if(!this.skuTableData[i].price){
                   this.$notify.error({
                     title: '失败',
                     message: '请输入价格'
                   })
                   return;
                 }
                 if(!this.skuTableData[i].vipPrice){
                   this.$notify.error({
                     title: '失败',
                     message: '请输入会员价'
                   })
                   return;
								 }
								 if (!this.skuTableData[i].stock) {
										this.$notify.error({
											title: '失败',
											message: '请输入库存'
										})
										return;
									}
								 const sku = {
                   originalPrice: parseInt(this.skuTableData[i].originalPrice * 100),
                   price: parseInt(this.skuTableData[i].price * 100),
                   vipPrice: parseInt(this.skuTableData[i].vipPrice * 100),
                   stock: this.skuTableData[i].stock,
                   img: this.skuTableData[i].img,
                   title,
                   specification
                 }
                 if (this.skuTableData[i].id) {
                   // 编辑SKU情况下，加上SkuId
                   sku.id = this.skuTableData[i].id
                 }
                 skuList.push(sku)
               }
               let spuList=[]
               this.specList.forEach(item=>{
                 if(item.id){
                   spuList.push({'title':item.title,id:item.id})
                 }else {
                   spuList.push({'title':item.title})
                 }
               })
               goodsParams.spuList=spuList;
               goodsParams.skuList=skuList;
             }else {
               goodsParams.spuList=[];
               let curSku={}

               if (!this.product.skuList[0].originalPrice||typeof(this.product.skuList[0].originalPrice)=="undefined"  || parseInt(curSku.originalPrice * 100) < 1){
                 this.$notify.error({
                   title: '失败',
                   message: '原价不能为空且金额需 ≥0.01'
                 })
                 return;
               }
               if (!this.product.skuList[0].price||typeof(this.product.skuList[0].price)=="undefined"  || parseInt(curSku.price * 100) < 1){
                 this.$notify.error({
                   title: '失败',
                   message: '现价不能为空且金额需 ≥0.01'
                 })
                 return;
               }
               if (this.product.skuList[0].stock && parseInt(this.product.skuList[0].stock ) < 0){
                 this.$notify.error({
                   title: '失败',
                   message: '库存不能小于0'
                 })
                 return;
               }
               this.product.skuList[0].stock = this.product.skuList[0].stock?parseInt(this.product.skuList[0].stock):0;
               Object.assign(curSku,this.product.skuList[0]);
               curSku.title='默认'
               delete curSku.specification
               curSku.price = parseInt(curSku.price * 100)
               curSku.originalPrice = parseInt(curSku.originalPrice * 100)
               curSku.vipPrice = parseInt(curSku.vipPrice * 100)
               goodsParams.skuList=[curSku]
             }
           //this.product.categoryId = this.categoryIds[2]
            let method = post
            let successWords = '创建成功'
            if (action === 'edit') {
              method = put
              successWords = '编辑成功'
            }
            console.log('当前数值',goodsParams)
            if(that.videoFileList && that.videoFileList.length) {
              goodsParams.videoUrl = `${that.videoFileList[0]}`
            }
           method('/goods',goodsParams).then(res=>{
            //  this.$router.go(-1);

             this.$router.push({ path: '/management' })
             this.$message({
               type: "success",
               message: successWords,
             });
           })
            // method(finalProduct)
            //   .then(response => {
            //     this.$notify.success({
            //       title: '成功',
            //       message: successWords
            //     })
            //     this.$router.push({ path: '/product/list' })
            //   })
            //   .catch(response => {
            //     MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
            //       confirmButtonText: '确定',
            //       type: 'error'
            //     })
            //   })
          }
        } else {
          this.$notify.error({
            title: '失败',
            message: '请完善以上表单'
          })
        }
      })
    },
    handleAttributeShow() {
      this.attributeForm = {}
      this.attributeVisiable = true
    },
    handleAttributeAdd() {
      this.$refs['attributeForm'].validate(valid => {
        if (valid) {
          this.attributes.unshift(this.attributeForm)
          this.attributeVisiable = false
        }
      })
    },
    handleAttributeDelete(row) {
      const index = this.attributes.indexOf(row)
      this.attributes.splice(index, 1)
    },
    // SKU相关
    handleSkuTagClose(index, tag, item) {
      item.values.splice(item.values.indexOf(tag), 1)
      this.loadSkuTable()
    },
    skuTableSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < this.specList.length) {
        // 若是前N列
        // 后面N - 1个
        let ji = 1
        for (let z = columnIndex + 1; z < this.specList.length; z++) {
          ji = ji * this.specList[z].values.length
        }
        if (rowIndex % ji === 0) {
          return {
            rowspan: ji, // 后面的乘积
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    loadSkuTable() {
      // 从specList中获取数据
      const attrList = this.specList
      const cacheTable = this.skuTableData
      let newTable = []

      for (let i = 0; i < attrList.length; i++) {
        const attr = attrList[i]
        const tmpArray = []

        if (i === 0) {
          for (let j = 0; j < attr.values.length; j++) {
            const tempTemp = []
            tempTemp.push(attr.values[j])
            tmpArray.push(tempTemp)
          }
          newTable = tmpArray
        } else {
          for (let z = 0; z < newTable.length; z++) {
            for (let j = 0; j < attr.values.length; j++) {
              const tempTemp = Object.assign([], newTable[z])
              tempTemp.push(attr.values[j])
              tmpArray.push(tempTemp)
            }
          }
          newTable = tmpArray
        }
      }
      const finalNewTable = []
      for (let i = 0; i < newTable.length; i++) {
        const obj = {}
        for (let j = 0; j < newTable[i].length; j++) {
          obj['id_' + attrList[j].title] = newTable[i][j]
        }
        for (let j = 0; j < cacheTable.length; j++) {
          let equals = true
          for (let z = 0; z < this.specList.length; z++) {
            const title = this.specList[z].title
            if (cacheTable[j]['id_' + title] !== obj['id_' + title]) {
              equals = false
            }
          }
          if (equals) {
            obj.originalPrice = cacheTable[j].originalPrice
            obj.price = cacheTable[j].price
            obj.originalPriceRaw = cacheTable[j].originalPriceRaw
            obj.vipPrice = cacheTable[j].vipPrice
            obj.vipPriceRaw = cacheTable[j].vipPriceRaw
            obj.priceRaw = cacheTable[j].priceRaw
            obj.stock = cacheTable[j].stock
            obj.id = cacheTable[j].id
            if(this.imgList.length>0){
              obj.img=this.imgList[0]
            }
          }
        }
        // 从缓存表中加上默认的值
        finalNewTable.push(obj)
      }
      // 变成对象
      this.skuTableData = finalNewTable
    },
    handleSkuTagInputConfirm(index, item) {
      const inputSkuTagValue = item.inputSkuTagValue
      if (item.values.indexOf(inputSkuTagValue) > -1) {
        this.$notify.warning({
          title: '失败',
          message: '请不要添加重复属性'
        })
      } else {
        if (inputSkuTagValue) {
          item.values.push(inputSkuTagValue)
        }
      }
      item.inputSkuTagVisible = false
      item.inputSkuTagValue = ''
      this.loadSkuTable()
    },
    handleSkuTagCreate(item) {
      item.inputSkuTagVisible = true
      this.$forceUpdate()
    },
    // 规格相关代码START
    createSpecDialogShow() {
      this.specStatus = 'create'
      this.specForm = {}
      this.specVisiable = true
    },
    updateSpecDialogShow(row) {
      this.specStatus = 'update'
      this.specForm = Object.assign({}, row)
      this.specVisiable = true
    },
    handleSpecAdd() {
      this.$refs['specForm'].validate(valid => {
        if (valid) {
          const title = this.specForm.title
          // 重复校验
          if (this.specList.indexOf(title) >= 0) {
            this.$notify.error({
              title: '失败',
              message: '规格维度不能重复'
            })
            return
          }
          const obj = Object.assign({}, this.specForm)
          obj.values = []
          obj.inputSkuTagVisible = false
          obj.gmtCreate = new Date().getTime()
          this.specList.push(obj)
          this.specVisiable = false
        }
      })
    },
    handleSpecDelete(row) {
      const index = this.specList.indexOf(row)
      this.specList.splice(index, 1)
    },
    randonBarcode(row) {
      row.barCode = new Date().getTime() + ''
      this.$forceUpdate()
    },
    handleBatchPrice() {
      for (let i = 0; i < this.skuTableData.length; i++) {
        if (this.skuBatchPriceForm.price) {
          this.skuTableData[i].price = this.skuBatchPriceForm.price
        }
        if (this.skuBatchPriceForm.vipPrice) {
          this.skuTableData[i].vipPrice = this.skuBatchPriceForm.vipPrice
        }
        if (this.skuBatchPriceForm.originalPrice) {
          this.skuTableData[i].originalPrice = this.skuBatchPriceForm.originalPrice
        }
        if (this.skuBatchPriceForm.stock) {
          this.skuTableData[i].stock = this.skuBatchPriceForm.stock
        }
      }
      this.skuBatchPriceVisiable = false
    },
    /** ******************* 图片上传相关 *********************/
    uploadOverrun: function() {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      })
    },
    handleimgsUrl(response, file, fileList) {
      if (response.code === 200) {
				this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.imgList.push(response.data)
        this.imgsFileList = this.imgList
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        this.imgsFileList = fileList.pop()
      }
    },
 			handleRemove: function(file, fileList) {
				// for (var i = 0; i < this.imgList.length; i++) {
				//   // 这里存在两种情况
				//   // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.url
				//   //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
				//   // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
				//   var url
				//   if (file.response === undefined) {
				//     url = file.url
				//   } else {
				//     url = file.response.url
				//   }

				//   if (this.imgList[i] === url) {
				//     this.imgList.splice(i, 1)
				//   }
        // }
				let reg = new RegExp(baseImgUrl, "g")
				let url = file.url.replace(reg, '')
				for (var i = 0; i < this.imgList.length; i++) {
					if (this.imgList[i] === url) {
						this.imgList.splice(i, 1)
					}
        }
        this.imgsFileList = fileList
        // console.log('this.imgList2', this.imgList)

			},
    beforSkuImgUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 1MB!')
      }
      return isLt2M
    },
    handleSkuImgSuccess(e, file, scope) {
      console.log("上传成功",e)
      this.$set(this.skuTableData[scope.$index], 'img', e.data)
    },
    noSpecialCode(e, type){
      conste.log(e)
      noSpecialCode(e, type)
    },
    test(val){
      console.log('test', val)
    },
  }
}
</script>

<style>
/*.el-upload--text{
  width: 60px;
  height: 60px;
}
.el-card {
  margin-bottom: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}*/

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

.sku-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.sku-uploader .el-upload:hover {
  border-color: #20a0ff;
}
.sku-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.sku {
  width: 60px;
  height: 60px;
  display: block;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.disabled .el-upload--picture-card {
		display: none!important;
}
.videoWrap .el-upload--text {
  width: 148px;
  height: 148px;
}
.videoWrap .el-icon-plus{
    font-size: 28px;
    color: #8c939d;
    line-height: 148px;
}
.skuWrap .el-upload--text{
  height: 60px;
  width: 60px;
}
.skuImgBox .sku-uploader .el-upload--text {
  height: 60px!important;
  width: 60px!important;
}
</style>
